import React from 'react';
import {Route, Routes} from 'react-router-dom';
import LoginApp from "./pages/LoginApp";
import SupportPage from "./pages/SupportPage";
import LandingPage from "./pages/LandingPage";
import LogoutPage from "./pages/LogoutPage";
import PartnerContext, {Partner} from "./partner-context";
import OpenAmServerInfoEndpoint from "./openam/OpenAmServerInfoEndpoint";
import {IntlProvider} from "react-intl";
import {loadWebCrypto} from "./utils/portal-webcrypto-loader";
import {backgrounds, bundles} from "./utils/constants";
import Loader from "./components/common/Loader";
import PageHeader from "./components/common/PageHeader";
// CSS
import './css/common.css';
import './css/clearstream.css';
import './css/cetip.css';
import './css/asx.css';
import './css/hqla.css';
import './css/hsbc.css';
import './css/tmx.css';
import './css/strate.css';
import './css/cfcl.css';
import './css/northerntrust.css';
import OpenAmSessionInfoEndpoint from "./openam/OpenAmSessionInfoEndpoint";
import {initialize} from "./utils/reactWebCryptoAdapter";
import ElementWrapper from "./components/common/ElementWrapper";


interface AppState {
  partner: Partner;
  isWebCryptoLoaded?: boolean;
  webCryptoInitError?: string;
  gotoUrl?: string;
}

class App extends React.Component<any, AppState> {
  constructor(props: any) {
    super(props);
    this.state = {
      // We do not want any partner styling as long as we do not know which partner is being targeted
      partner: Partner.Undefined
    };
  }

  componentDidMount(): void {
    loadWebCrypto()
      .then(_ => initialize())
      .catch((reason => this.setState({webCryptoInitError: reason})))
      .finally(() => this.setState({
          isWebCryptoLoaded: true,
          gotoUrl: this.getGotoParam()
        })
      )
  }

  componentWillMount() {
    OpenAmSessionInfoEndpoint.hasUserValidSession().then((hasUserValidSession: boolean) => {
      if (hasUserValidSession && !this.getGotoParam()) {
        window.location.replace('/portal/');
      }
    }).catch((e: Error) => {
      console.error("Could not retrieve session information: " + e);
    });

    let that = this;
    OpenAmServerInfoEndpoint.call().then(function (response) {
      switch (response.realm) {
        case "/clearstream":
          that.setState({
            partner: Partner.Clearstream
          });
          break;
        case "/cetip":
          that.setState({
            partner: Partner.Cetip
          });
          break;
        case "/asx":
          that.setState({
            partner: Partner.Asx
          });
          break;
        case "/hqla":
          that.setState({
            partner: Partner.Hqla
          });
          break;
        case "/hsbc":
          that.setState({
            partner: Partner.Hsbc
          });
          break;
        case "/tmx":
          that.setState({
            partner: Partner.Tmx
          });
          break;
        case "/strate":
          that.setState({
            partner: Partner.Strate
          });
          break;
        case "/cfcl":
          that.setState({
            partner: Partner.Cfcl
          });
          break;
        case "/northerntrust":
        that.setState({
          partner: Partner.Northerntrust
        });
        break;
      }

      document.body.style.backgroundImage =
        'url(' + backgrounds[that.state.partner][Math.floor(Math.random() * backgrounds[that.state.partner].length)] + ')';
    });
  }

  getGotoParam = (): string | undefined => {
    const params = new URLSearchParams(window.location.search);
    return params.get('goto') || undefined;
  };

  render() {
    if (this.state.partner === Partner.Undefined || !this.state.isWebCryptoLoaded) {
      return <Loader/>
    }

    return (
      <main>
        <PartnerContext.Provider value={this.state.partner}>
          <IntlProvider locale={navigator.language} messages={bundles[this.state.partner]}>
            <PageHeader/>
            <Routes>
              <Route path="/" element={<LandingPage/>}/>
              <Route path="/logout" element={<LogoutPage/>}/>
              <Route path="/support" element={<ElementWrapper routeElement={SupportPage}/>}/>
              <Route path="/login"
                     element={<LoginApp
                       realm={this.state.partner}
                       goto={this.state.gotoUrl}/>}
              />
            </Routes>
          </IntlProvider>
        </PartnerContext.Provider>
      </main>
    );
  }
}

export default App;
